<script lang="ts">
export default {
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<template>
  <div class="headings">
    <h2>Home</h2>
    <h3>The glossa admin interface</h3>
  </div>
  <article>
    <header>
      <h3>Use Glossa for integrating comments into your website!</h3>
    </header>
    <p>
      Do you have a full account registered with Glossa and want to manage it?
      <router-link :to="{'name': 'login'}" role="button">Login to your account</router-link>
    </p>
    <p>
      Is Glossa the right tool for you? Create a full account and start using it in 3 minutes!
      <router-link :to="{'name': 'signup'}" role="button">Sign up for a new account</router-link>
    </p>
  </article>
  <article>
    <header>
      <h3>Try out glossa for free, without the need to register!</h3>
    </header>
    <p>
      Are you currently evaluating Glossa using a trial account?
      <router-link :to="{'name': 'trial-login'}" role="button">Login to your trial account</router-link>
    </p>
    <p>
      Do you want to evaluate Glossa? Get 14 days to try it out for free!
      <router-link :to="{'name': 'trial-signup'}" role="button">Create a trial account</router-link>
    </p>
  </article>
</template>

<style scoped>

header h3 {
  margin-bottom: 0;
}

article {
  padding-bottom: calc(var(--spacing) * 2);
}
  a {
    display: block;
    margin: 1em 30%;
  }
  p {
    margin-bottom: 3em;
  }
  article {
    text-align: center;
  }
</style>

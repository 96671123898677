import { reactive, customRef, readonly } from "vue";

const prefix = 'glossa-admin.settings.'


function getAuthToken() {
  const authToken = localStorage.getItem(prefix+'authToken')
  const expirationDateString = localStorage.getItem(prefix+'authTokenExpire')
  const leftExpireTime = new Date(expirationDateString) - new Date()
  if (authToken && expirationDateString && leftExpireTime > 0) {
    return expiringRef(authToken, leftExpireTime)
  }
  return null
}

const settings = reactive({
  authToken: getAuthToken(),
  setAuthToken(token: string, expireDate: Date) {
    localStorage.setItem(prefix+'authToken', token)
    localStorage.setItem(prefix+'authTokenExpire', String(expireDate.toJSON()))
    settings.authToken = expiringRef(token, (expireDate - new Date()))
  }
})


function expiringRef(value, expire: number) {
  return customRef((track, trigger) => {
    const timeout = setTimeout(() => {
      value = null
      trigger()
    }, expire)
    return {
      get() {
        track()
        return value
      },
      set(newValue) {
        clearTimeout(timeout)
      }
    }
  })
}

export default readonly(settings);
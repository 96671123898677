import { watch } from "vue";
import { createRouter, createWebHistory } from 'vue-router'

import Collection from '../views/Collection.vue'
import Collections from '../views/Collections.vue'
import CreateDomain from '../views/CreateDomain.vue'
import Domains from '../views/Domains.vue'
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import LoginTrial from "../views/LoginTrial.vue";
import SignUp from "../views/SignUp.vue";
import SignUpTrial from "../views/SignUpTrial.vue";
import Support from "../views/Support.vue";

import settings from "../settings";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      children: [
        {
          path: '/domains',
          name: 'domains',
          component: Domains
        },
        {
          path: '/domains/:domain/create/:plan',
          name: 'create-domain',
          component: CreateDomain,
          props: true,
        },
        {
          path: '/domains/:domain/collections',
          name: 'collections',
          component: Collections,
          props: true
        },
        {
          path: '/domains/:domain/collections/:collectionKey',
          name: 'collection',
          component: Collection,
          props: true
        }
      ]
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUp,
    },
    {
      path: '/support',
      name: 'support',
      component: Support,
    },
    {
      path: '/trial/signup',
      name: 'trial-signup',
      component: SignUpTrial
    },
    {
      path: '/trial/login/:accountId?',
      name: 'trial-login',
      component: LoginTrial,
      props: true,
    },
  ]
})
router.beforeEach(async (to, from) => {
  if (
    // make sure the user is authenticated
    ! settings.authToken &&
    // ❗️ Avoid an infinite redirect
    ['domains', 'create-domain', 'collection', 'collections'].includes(to.name)
  ) {
    // redirect the user to the login page
    console.log("Redirect to home")
    return { name: 'home' }
  }
})
router.beforeEach(async (to, from) => {
  if (
    // make sure the user is authenticated
    settings.authToken && to.name == 'home'
  ) {
    // redirect the user to the login page
    console.log("Redirect to domains")
    return { name: 'domains' }
  }
})

watch(
() => settings.authToken,
  (newAuthToken, prevAuthToken) => {
    if(newAuthToken === null) {
      router.push('/')
    } else if (newAuthToken !== null && prevAuthToken === null) {
      router.push('/domains')
    }
  }
)

export default router

<script lang="ts">

import settings from "../settings";

export default {
  inject: ['backend'],
  props: ['accountId'],
  data() {
    return {
      isLoading: false,
      error: '',
    }
  },
  created() {

  },
  methods: {
    login() {
      this.isLoading = true;
      this.backend.getAuthTokenForTrial(this.accountId)
        .then((response) => {
          this.idToken = response.idToken
          const expireDate = new Date()
          expireDate.setTime(expireDate.getTime() + response.accessTokenExpires*1000)
          settings.setAuthToken(response.idToken, expireDate)
          this.$router.push({'name': 'domains'})
        })
        .catch((error) => {
          this.error = String(error)
        })
      .finally(() => {
        this.isLoading = false
      })
    },
  },
  computed: {
    isAccountInvalid() {
      return ! this.accountId || this.accountId.indexOf('trial-') != 0
    }
  }
}
</script>

<template>
  <div class="headings">
    <h2>Login to a trial account</h2>
    <h3></h3>
  </div>
  <article>
    <form>
      <label for="account-id">Account Id</label>
      <input
        type="text" id="account-id" name="account-id" placeholder="account id" required
        v-model="accountId"
      >

      <button type="submit" @click.prevent="login" :disabled="isAccountInvalid" :aria-busy="isLoading">Login</button>
      <p class="error">{{ error }}</p>
    </form>
  </article>

</template>

<style lang="scss">
</style>

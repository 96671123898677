<script lang="ts">
export default {
}
</script>

<template>
  <span><slot></slot></span>
</template>

<style scoped lang="scss">

span {
  background: darkred;
  border-radius: 1em;
  color: #fff;
  padding: 0.25em 0.5em;
  font-size: 0.8em;
  text-align: center;
  margin-right: 1em;
  vertical-align: middle;
}


</style>

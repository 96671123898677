<template>
  <dialog open>
    <article>
      <header>
        <a href="#close" aria-label="Close" class="close" @click="closeHandler"></a>
        <slot name="title"></slot>
      </header>
      <slot name="content"></slot>
      <footer><slot name="footer">
        <a href="#confirm" role="button" @click="closeHandler">Ok</a>
      </slot></footer>
    </article>
  </dialog>
</template>

<script>
export default {
  props: ['closeHandler'],
  name: "Dialog"
};
</script>

<style>
dialog h3 {
  margin: 0;
}
</style>
<script lang="ts">
import IntegrationCode from "../components/IntegrationCode.vue";
import TrialAccountLogin from "../components/TrialAccountLogin.vue";
import { createBackend } from "../backend";

const backend = createBackend();

export default {
  inject: ['backend'],
  data() {
    return {
      domain: '',
      accountId: null,
      apiKey: null,
      isLoading: false,
    }
  },
  components: {
    IntegrationCode,
    TrialAccountLogin,
  },
  methods: {
    createTrialAccount() {
      this.isLoading = true;
      backend.createTrialAccount()
        .then((account) => {
          this.accountId = account.accountId
          return backend.getAuthTokenForTrial(this.accountId)
        })
        .then((response) => {
          const adminBackend = backend.getAdminBackend(response.idToken)
          return adminBackend.createDomain(this.domain)
        })
        .then((response) => {
          this.domain = response['name']
          this.apiKey = response['apiKey']
        })
        .catch((response) => {})
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  computed: {
    isDomainInvalid() {
      return ! /^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$/.test(this.domain)
    }
  }
}
</script>

<template>
  <h1>Create a trial account</h1>
  <article v-if="! apiKey">
    <h2>Which domain will be used for the trial?</h2>
    <form>
      <!-- Markup example 2: input is after label -->
      <label for="domain">Domain</label>
      <input
        type="text" id="domain" name="domain" placeholder="domain.tld" required
        v-model="domain"
      >
      <small>
        We will create a test account which allows integrating glossa into any
        web page running under the given domain. The test account has a
        limitation to 5,000 requests and will only last for 14 days.
      </small>

      <!-- Button -->
      <button type="submit" @click.prevent="createTrialAccount" :disabled="isDomainInvalid" :aria-busy="isLoading">Sign up</button>
    </form>
  </article>

  <article v-if="accountId && apiKey && ! isLoading">
    <h2>Success!</h2>
    <p>
      The trial account for domain {{ domain }} was successfully created. You can login to its
      administration interface by the following link:
    </p>
    <TrialAccountLogin :account-id="accountId"></TrialAccountLogin>
    <h4>Account Id</h4>
    <p>
      <code class="block">
        {{ accountId }}
      </code>
    </p>
    <IntegrationCode :api-key="apiKey" v-if="apiKey"></IntegrationCode>
  </article>
</template>

<style lang="scss">
</style>

<script lang="ts">
export default {
  inject: ['adminBackend'],
  props: ['domain'],
  data() {
    return {
      collections: [],
      isLoading: false,
    }
  },
  mounted() {
    this.initializeCollections()
  },
  methods: {
    initializeCollections() {
      this.isLoading = true
      this.adminBackend().listCollections(this.domain).then((collections) => {
        this.collections = collections
      })
      .finally(() => {
        this.isLoading = false
      })
    },
  }
}
</script>

<template>
  <div class="headings">
    <h2>Collections in {{  domain  }}</h2>
    <h3>Show all collections which contain comments</h3>
  </div>
  <nav class="breadcrumbs">
    <ul>
      <li><router-link :to="{ name: 'domains' }">Domains</router-link></li>
      <li><router-link :to="{ name: 'collections', params: {domain: domain } }">{{ domain }}</router-link></li>
    </ul>
  </nav>
  <article aria-busy="true" v-if="isLoading"></article>
  <article v-if="! isLoading">
    <table role="grid">
      <thead>
      <tr>
        <th scope="col">Collection</th>
        <th scope="col">Key</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="collection in collections">
        <th scope="row">
          <router-link
            :to="{ name: 'collection', params: {domain: domain, collectionKey: collection.key } }">
            {{ collection.name }}
          </router-link>
        </th>
        <td>{{ collection.key }}</td>
      </tr>
      </tbody>
      <!--        <tfoot>-->
      <!--        <tr>-->
      <!--          <th scope="col">#</th>-->
      <!--          <td scope="col">Total</td>-->
      <!--          <td scope="col">Total</td>-->
      <!--          <td scope="col">Total</td>-->
      <!--          <td scope="col">Total</td>-->
      <!--          <td scope="col">Total</td>-->
      <!--        </tr>-->
      <!--        </tfoot>-->
    </table>
  </article>
</template>

<style scoped>
</style>

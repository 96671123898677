<script lang="ts">
import { marked } from "marked";
import DOMPurify from 'dompurify';

import Badge from "../components/Badge.vue";
import Dialog from "../components/Dialog.vue";

const dateFormat = new Intl.DateTimeFormat('en', { timeStyle: 'short', dateStyle: 'short' })

export default {
  inject: ['adminBackend'],
  props: ['domain', 'collectionKey'],
  data() {
    return {
      collection: { },
      isLoading: false,
      commentToBeDeleted: null,
      threadToBeDeleted: null,
    }
  },
  components: {
    Badge, Dialog,
  },
  mounted() {
    this.initializeCollection()
  },
  methods: {
    initializeCollection() {
      this.isLoading = true
      this.adminBackend().getCollection(this.domain, this.collectionKey).then((collection) => {
        this.collection = collection
        this.collection.threads.sort((a, b) => b.timestamp -  a.timestamp)
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    compiledMarkdown(text: string) {
      return DOMPurify.sanitize(marked(text))
    },
    formatTimestamp(timestamp: number) {
      // Timestamp is number of seconds, javascript uses milliseconds
      return dateFormat.format(timestamp*1000);
    },
    approveComment(thread, comment) {
      if(comment.needsApproval) {
        this.adminBackend().updateComment(this.domain, this.collectionKey, thread.id, comment.timestamp, {isApproved: true}).then(() => {
          comment.isApproved = true
        })
      }
    },
    confirmDeleteComment(thread, comment) {
      this.commentToBeDeleted = comment
      this.threadToBeDeleted = thread
    },
    cancelDeleteComment() {
      this.commentToBeDeleted = null
      this.threadToBeDeleted = null
    },
    deleteComment(thread, comment) {
      this.adminBackend().deleteComment(this.domain, this.collectionKey, thread.id, comment.timestamp).then(() => {
        const index = thread.comments.indexOf(comment)
        if(index > -1) {
          thread.comments.splice(index, 1)
        }
      })
      this.threadToBeDeleted = null
      this.commentToBeDeleted = null
    },
    confirmDeleteThread(thread) {
      this.threadToBeDeleted = thread
    },
    cancelDeleteThread() {
      this.threadToBeDeleted = null
    },
    deleteThread(thread) {
      this.adminBackend().deleteThread(this.domain, this.collectionKey, thread.id).then(() => {
        const index = this.collection.threads.indexOf(thread)
        if(index > -1) {
          this.collection.threads.splice(index, 1)
        }
      })
      this.threadToBeDeleted = null
    }
  }
}
</script>

<template>
  <div class="headings">
    <h2>Collection {{  collection.name  }}</h2>
    <h3>Collection key {{  collection.key  }}</h3>
  </div>
  <nav class="breadcrumbs">
    <ul>
      <li><router-link :to="{ name: 'domains' }">Domains</router-link></li>
      <li><router-link :to="{ name: 'collections', params: {domain: domain } }">{{ domain }}</router-link></li>
      <li><router-link :to="{ name: 'collection', params: {domain: domain, collection: collectionKey } }">{{  collection.name  }}</router-link></li>
    </ul>
  </nav>
  <article aria-busy="true" v-if="isLoading"></article>
  <article v-if="! isLoading">
    <header>
      Threads
    </header>

    <details v-for="thread in collection.threads">
      <summary><span style="float:left; padding-right: 2em">{{ formatTimestamp(thread.timestamp) }}</span>
        {{ thread.subject.text }}
      </summary>
      <main class="comment-list">
        <a href="#" role="button" class="dangerous"
           @click.prevent="confirmDeleteThread(thread)">
          Delete whole thread
        </a>
        <blockquote v-for="comment in thread.comments.slice().reverse()">
          <div class="actions">
            <a href="#" role="button"
                v-if="comment.needsApproval && ! comment.isApproved"
                @click.prevent="approveComment(thread, comment)">
              Approve
            </a>
            <a href="#" role="button" class="dangerous"
               @click.prevent="confirmDeleteComment(thread, comment)">
              Delete
            </a>
          </div>
          <footer>
            <Badge v-if="comment.isSpam">spam</Badge>
            <Badge class="approved" v-if="comment.isApproved">approved</Badge>
            <cite v-if="comment.author">{{ comment.author }}</cite>
            <span class="timestamp">{{ formatTimestamp(comment.timestamp) }}</span>
          </footer>
          <span class="content" v-html="compiledMarkdown(comment.text)"></span>
        </blockquote>
      </main>
    </details>
  </article>
  <Dialog v-if="commentToBeDeleted" :close-handler="cancelDeleteComment">
    <template v-slot:title>
      <h3>Delete comment</h3>
    </template>
    <template v-slot:content>
      <p>Do you really want to delete this comment?</p>
    </template>
    <template v-slot:footer>
      <a href="#cancel"
         role="button"
         class="secondary"
         @click="cancelDeleteComment">
        Cancel
      </a>
      <a class="dangerous" href="#confirm"
         role="button"
         data-target="modal-example"
         @click="deleteComment(threadToBeDeleted, commentToBeDeleted)">
        Confirm
      </a>
    </template>
  </Dialog>
  <Dialog v-if="threadToBeDeleted && ! commentToBeDeleted" :close-handler="cancelDeleteThread">
    <template v-slot:title>
      <h3>Delete thread</h3>
    </template>
    <template v-slot:content>
      <p>Do you really want to delete this thread?</p>
    </template>
    <template v-slot:footer>
      <a href="#cancel"
         role="button"
         class="secondary"
         @click="cancelDeleteThread">
        Cancel
      </a>
      <a class="dangerous" href="#confirm"
         role="button"
         data-target="modal-example"
         @click="deleteThread(threadToBeDeleted)">
        Confirm
      </a>
    </template>
  </Dialog>
</template>

<style lang="scss">

details summary::after {
  position: absolute;
  right: 0em;
  top: 0em;
}

details summary {
  padding-right: 2em;
  position: relative;
}

span.content p {
  margin-bottom: 0;
}

.comment-list > blockquote > footer {
  margin-top: 0;

  .timestamp {
    margin-left: 4em;
  }
}

.approved {
  background-color: darkcyan !important;
}
</style>

<script lang="ts">
import { toRefs } from "vue";
import { Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-vue';

import settings from "../settings";

const auth = useAuthenticator();
const { route, user, signOut } = toRefs(useAuthenticator());


const services = {
  async handleSignIn(formData) {
    let { username, password } = formData;
    return Auth.signIn({
      username,
      password,
    }).then((result) => {
      const expire = new Date(new Date().getTime() + (3600 * 1000))
      const idToken = result.signInUserSession.idToken.jwtToken
      settings.setAuthToken(idToken, expire)
      return result
    });
  },
};

export default {
  data() {
    return {
      user: user,
      route: route,
      isLoading: false,
      services: services,
      settings: settings,
    }
  },
  watch: {
    route(newRoute, oldRoute) {
      if(newRoute === 'authenticated' && ! this.settings.authToken) {
        auth.signOut()
      }
    }
  },
}
</script>

<template>
  <div class="actionbar">
    <LogoutButton></LogoutButton>
  </div>
  <div class="headings" v-if="! settings.authToken">
    <h2>Login</h2>
    <h3>Log in to a registered account</h3>
  </div>
  <article v-show="! settings.authToken">
    <authenticator :login-mechanisms="['email']" :services="services" :hide-sign-up="true">
    </authenticator>
  </article>
  <router-view></router-view>
</template>

<style lang="scss">
.amplify-flex {
  flex-direction: row;
  display: flex;
}

div.amplify-field-group {
  div.amplify-field-group__field-wrapper {
    flex-grow: 1;
  }
  button {
    width: 3.3em;
    display: inline-block;
  }
  input[type=number] {
    -moz-appearance:textfield;
    appearance: textfield;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

div.actionbar {
  display: inline-block;
  float: right;
}
</style>

<script>
export default {
  inject: ['env'],
  props: {
    apiKey: String
  }
};
</script>

<template>
  <h4>API Key</h4>
  <p>
  <code class="block">
    {{ apiKey }}
  </code>
  </p>
  <h4>Integration</h4>
  <p>
    Add the following code snippet to your HTML pages. This code automatically
    fetches the glossa client and initializes it with your API key.
    <code class="block">
      <pre>
          ...
          &lt;script type="module"&gt;
            import { initGlossa } from "{{ env.cdnUrl }}glossa-client.es.js"
            initGlossa('{{ apiKey }}');
          &lt;/script&gt;
          ...
        &lt;/body&gt;
      &lt;/html&gt;
      </pre>
    </code>
  </p>
</template>

<style scoped>

</style>
import settings from "@/settings";


function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};


function getUser() {
  if(settings.authToken) {
    const tokenContent = parseJwt(settings.authToken);
    return {
      'userName': tokenContent['cognito:username'],
      'isTrialUser': tokenContent['cognito:username'].startsWith('trial-'),
      'email': tokenContent['email'],
    }
  }
  return null
}

export { getUser }

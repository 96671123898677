const hostname = new URL(window.location.href).hostname
const env = {
  cdnUrl: 'https://cdn.glossa.cc/',
  backendServiceUrl: 'https://api.glossa.cc/',
  stage: 'prod',
  suffix: '',
  awsconfig: {
    Auth: {
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_xYz4JDACc',
      userPoolWebClientId: '5sop1912b7s9iu80s8tp4clode'
    }
  }
}

if(hostname.indexOf('admin-test') > -1) {
  env['cdnUrl'] = 'https://cdn-test.glossa.cc/'
  env['backendServiceUrl'] = 'https://api-test.glossa.cc/'
  env['awsconfig']['Auth']["userPoolId"] = 'eu-central-1_o0so8aHxj'
  env['awsconfig']['Auth']["userPoolWebClientId"] = '5ij7jeq5c4lq2n6mpeargngvgl'
  env['stage'] = 'test'
  env['suffix'] = '-test'

} else if(hostname.indexOf('admin-dev') > -1) {
  env['cdnUrl'] = 'https://cdn-dev.glossa.cc/'
  env['backendServiceUrl'] = 'https://api-dev.glossa.cc/'
  env['awsconfig']['Auth']["userPoolId"] = 'eu-central-1_yw9V1LRwv'
  env['awsconfig']['Auth']["userPoolWebClientId"] = '3p2o7kedds33fv330jbronff5j'
  env['stage'] = 'dev'
  env['suffix'] = '-dev'

} else if (hostname.indexOf('localhost') > -1) {
  env['cdnUrl'] = 'https://cdn-dev.glossa.cc/',
  env['backendServiceUrl'] = 'http://localhost:5000/'
  env['awsconfig']['Auth']["userPoolId"] = 'eu-central-1_yw9V1LRwv'
  env['awsconfig']['Auth']["userPoolWebClientId"] = '3p2o7kedds33fv330jbronff5j'
  env['stage'] = 'local'
  env['suffix'] = '-local'
} else if (hostname.indexOf('admin.glossa.cc') == -1) {
  throw new Error("Unsupported environment with host " + hostname)
}

export default env

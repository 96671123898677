<script>
export default {
  props: {
    accountId: String
  },
  computed: {
    adminLoginUrl() {
      const route = this.$router.resolve({ name: 'trial-login', params: { accountId: this.accountId } });
      return new URL(route.href, window.location.href).href;
    }
  }
};
</script>

<template>
  <h4>Trial account login</h4>
  <p>
    <router-link :to="{ name: 'trial-login', params: { accountId: accountId } }">{{ adminLoginUrl }}</router-link>
  </p>
</template>

<style scoped>

</style>
<script lang="ts">
import { toRefs } from "vue";
import { useAuthenticator } from '@aws-amplify/ui-vue';

import settings from "../settings";

const auth = useAuthenticator();
const { route, user, signOut } = toRefs(auth);


export default {
  props:['action'],
  data() {
    return {
      user: user,
      route: route,
      isLoading: false,
    }
  },
  watch: {
    user(newUser, oldUser) {
      if (newUser) {
        const idToken = newUser.signInUserSession.idToken.jwtToken
        const expire = new Date(newUser.signInUserSession.idToken.payload.exp * 1000)
        settings.setAuthToken(idToken, expire)
        console.log("Redirect after user to domains")
        this.$router.push({name: 'domains'})
      }
    }
  }
}
</script>

<template>
  <div class="headings">
    <h2>Sign up</h2>
    <h3>Create a new account</h3>
  </div>
  <article>
    <authenticator :login-mechanisms="['email']" initial-state="signUp">
      <template v-if="route == 'authenticated'">
       Successfully signed up. <router-link to="/">Home</router-link>
      </template>
    </authenticator>
  </article>

</template>

<style lang="scss">
.amplify-flex {
  flex-direction: row;
  display: flex;
}

div.amplify-alert {
  > button, div svg {
    width: 3.3em;
  }
}

div.amplify-tabs {
  display: none;
}

div.amplify-field-group {
  div.amplify-field-group__field-wrapper {
    flex-grow: 1;
  }
  button {
    width: 3.3em;
    display: inline-block;
  }
  input[type=number] {
    -moz-appearance:textfield;
    appearance: textfield;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>

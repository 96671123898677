<script>
import IntegrationCode from "../components/IntegrationCode.vue";

export default {
  inject: ['adminBackend'],
  props: ['domain', 'plan'],
  components: {
    IntegrationCode
  },
  data() {
    return {
      isBusy: false,
      apiKey: null,
      result: null,
      sessionId: null,
      subscriptionId: '',
      threadToBeDeleted: null,
    }
  },
  mounted() {
    const url = new URL(window.location.href)
    this.sessionId = url.searchParams.get('sessionId')
    this.result = url.searchParams.get('result')
    if(this.result == 'paid') {
      if(url.searchParams.has('sessionId')) {
        url.searchParams.delete('sessionId')
        history.pushState(this.sessionId, null, url.href)
        this.isBusy = true
        this.adminBackend().getSubscriptionFromCheckout(this.sessionId).then((response) => {
          this.subscriptionId = response.subscriptionId
          this.createDomain()
        })
      } else {
        this.createDomain()
      }
    }
  },
  methods: {
    createDomain() {
      return this.adminBackend().createDomain(this.domain, this.plan, this.subscriptionId).then((response) => {
        this.result = 'created'
        this.apiKey = response.apiKey
        const url = new URL(window.location.href)
        url.searchParams.set('result', 'created')
        history.pushState(this.subscriptionId, null, url.href)
      }).finally(() => {
        this.isBusy = false
      })
    },
  }
};
</script>

<template>
  <div class="headings">
    <h2>Create new domain {{  domain  }}</h2>
    <h3></h3>
  </div>
  <nav class="breadcrumbs">
    <ul>
      <li><router-link :to="{ name: 'domains' }">Domains</router-link></li>
    </ul>
  </nav>
  <article v-if="result == 'paid'">
    <p>
      Thank you, we successfully received the payment for your new subscription.
      <br>Please wait for the creation of the domain...
    </p>
    <progress indeterminate="true" v-if="isBusy"></progress>
  </article>
  <article v-if="result == 'created'">
    <p>
      Successfully created the new domain {{ domain }}.
    </p>
    <p v-if="apiKey">
      You can start to use the new plan with the following integration code:
    </p>
    <IntegrationCode :api-key="apiKey" v-if="apiKey"></IntegrationCode>
    <router-link :to="{ name: 'domains' }">Back to domains</router-link>
  </article>
  <article v-if="result == 'cancel'">
    <p>
      Creating the new domain {{ domain }} was canceled.
    </p>
    <router-link :to="{ name: 'domains' }">Back to domains</router-link>
  </article>

</template>

<style scoped>

</style>
import { Amplify } from 'aws-amplify';
import { Authenticator } from "@aws-amplify/ui-vue";
import { createApp } from 'vue'


import App from './App.vue'
import router from './router'
import { createBackend } from "./backend";
import settings from "./settings";
import env from "./env";

import LogoutButton from "./components/LogoutButton.vue"


Amplify.configure(env.awsconfig);


const backend = createBackend()

const app = createApp(App)

app.use(router)
app.provide('env', env)
app.provide('backend', backend)
app.provide('adminBackend', () => {
  if(settings.authToken) {
    return backend.getAdminBackend(settings.authToken)
  }
  throw Error("No valid authorization token.")
})
app.component('LogoutButton', LogoutButton)
app.component('authenticator', Authenticator)

app.mount('#app')


<script lang="ts">

import { getUser } from "../user";

export default {
  inject: ['backend'],
  data() {
    return {
      isMessageSent: false,
      email: getUser()?.email,
      username: getUser()?.userName,
      message: '',
      name: '',
    }
  },
  methods: {
    submitForm() {
      this.backend.sendSupportMessage(this.email, this.message, this.name, this.username).then(()=> {
        this.isMessageSent = true
      })
    },
  },
  computed: {
    isValid() {
      return this.message && this.email && document.getElementById('supportForm').reportValidity()
    }
  }
}
</script>

<template>
  <div class="headings">
    <h2>Support</h2>
    <h3>Contact us if you have questions or need assistance.</h3>
  </div>
  <article>
    <form id="supportForm" @submit.prevent="submitForm" v-if="! isMessageSent">

      <label for="name">Name</label>
      <input type="text" id="name" name="name" placeholder="Name" v-model="name">

      <label for="email">Email address</label>
      <input type="email" id="email" name="email" placeholder="Email address" required v-model="email">

      <label for="message">Message</label>
      <textarea id="message" name="message" placeholder="Your message to us" required v-model="message"></textarea>
      <input type="hidden" name="username" :value="username">
      <button type="submit" :disabled="! isValid">Submit</button>
    </form>
    <p v-if="isMessageSent">
      Your message has been successfully sent to our support team. You will receive
      a response via email to {{ email }}.
    </p>
  </article>
</template>

<style lang="scss">
</style>

<script lang="ts">
import { useAuthenticator } from "@aws-amplify/ui-vue";
import { toRefs } from "vue";

import settings from "../settings";

const auth = useAuthenticator();
const { route, user, signOut } = toRefs(useAuthenticator());


export default {
  data() {
    return {
      settings: settings,
    }
  },
  methods:{
    logout() {
      settings.setAuthToken(null, new Date())
      if(auth.route === 'authenticated') {
        auth.signOut()
      }
    }
  },
}
</script>

<template>
  <button v-if="settings.authToken" @click="logout">Logout</button>
</template>

<style scoped lang="scss">

</style>

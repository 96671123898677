// import { nextTick } from "petite-vue";
// import {CommentData, ThreadData} from "./datatypes";

const module_url = window.location.href

async function digestMessage(message: string, length=64) {
  const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex.substring(0, length);
}


interface Backend {
  getAuthTokenForTrial(accountId): Promise;
  createTrialAccount(): Promise;
  getAdminBackend(authToken): AdminBacked;
  sendSupportMessage(email, message, name, username): Promise;
}


interface AdminBacked {
  createDomain(domain): Promise;
  listDomains(): Promise;
  getUsagesForDomain(domainName): Promise;
}


function createBackend(): Backend {

  let backendUrl = 'https://api.glossa.cc'
  if(module_url.indexOf('admin-test.glossa.cc') > -1) {
    backendUrl = 'https://api-test.glossa.cc'
  } else if(module_url.indexOf('admin-dev.glossa.cc') > -1) {
    backendUrl = 'https://api-dev.glossa.cc'
  } else if(module_url.indexOf('localhost') > -1) {
    backendUrl = 'https://api-dev.glossa.cc'
  }

  return new ServiceBackend(backendUrl);
}



class ServiceBackend implements Backend {
  backendUrl: string;

  constructor(backendUrl: string) {
    this.backendUrl = backendUrl
  }

  createTrialAccount() : Promise {
    return fetch(this.backendUrl+'/trial', {
      method : "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body : JSON.stringify({})
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else {
        throw Error(response.statusText)
      }
    });
  }

  getAuthTokenForTrial(accountId): Promise {
    return fetch(this.backendUrl+'/trial/login', {
      method : "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body : JSON.stringify({accountId: accountId})
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else if (response.status == 404) {
        return response.json().then((obj) => {
          throw Error(obj.error.msg)
        })
      } else {
        throw Error(response.statusText)
      }
    });
  }

  sendSupportMessage(email, message, name = '', username = ''): Promise {
    return fetch(this.backendUrl+'/support/case', {
      method : "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body : JSON.stringify({
        email: email,
        message: message,
        name: name,
        username: username,
      })
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else {
        throw Error(response.statusText)
      }
    });
  }

  getAdminBackend(authToken) {
    return new _AdminServiceBackend(this.backendUrl, authToken);
  }
}

class _AdminServiceBackend implements AdminBacked {
  backendUrl: string;
  authToken: string;

  constructor(backendUrl: string, authToken: string) {
    this.backendUrl = backendUrl + '/admin'
    this.authToken = authToken
  }

  createDomain(domain, plan = 'Trial', sessionId = null): Promise {
    return fetch(this.backendUrl + '/domains', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.authToken,
      },
      body: JSON.stringify({ 'domain': domain, 'plan': plan, 'subscriptionId': sessionId })
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else {
        throw Error(response.statusText)
      }
    });
  }

  listDomains(): Promise {
    return fetch(this.backendUrl + '/domains', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.authToken,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else {
        throw Error(response.statusText)
      }
    });
  }

  getPlans(): Promise {
    return fetch(this.backendUrl + '/billing/plans', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.authToken,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else {
        throw Error(response.statusText)
      }
    });
  }

  createCheckoutSession(domain, plan, email, successUrl, cancelUrl): Promise {
    const subscriptionRequest = {
      'domain': domain,
      'plan': plan,
      'email': email,
      'successUrl': successUrl,
      'cancelUrl': cancelUrl,
    }
    return fetch(this.backendUrl + '/billing/checkout', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.authToken,
      },
      body: JSON.stringify(subscriptionRequest)
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else {
        throw Error(response.statusText)
      }
    });
  }

  getSubscriptionFromCheckout(sessionId): Promise {
    return fetch(this.backendUrl + '/billing/checkout/' + sessionId, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.authToken,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else {
        throw Error(response.statusText)
      }
    });
  }

  getSubscriptionManagementUrl(subscriptionId, returnUrl): Promise {
    const url = new URL(this.backendUrl + '/billing/subscriptions/' + subscriptionId)
    url.searchParams.set('return', returnUrl)
    return fetch(url.href, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.authToken,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else {
        throw Error(response.statusText)
      }
    });
  }

  getUsagesForDomain(domain): Promise {
    return fetch(this.backendUrl + '/domains/' + domain + '/usage', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.authToken,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else {
        throw Error(response.statusText)
      }
    });
  }

  listCollections(domain): Promise {
    return fetch(this.backendUrl + '/domains/' + domain + '/collections', {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.authToken,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else {
        throw Error(response.statusText)
      }
    });
  }

  getCollection(domain, collectionKey): Promise {
    return fetch(this.backendUrl + '/domains/' + domain + '/collections/'+collectionKey, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.authToken,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else {
        throw Error(response.statusText)
      }
    });
  }

  updateComment(domain, collectionKey, threadId, commentTimestamp, commentUpdate) : Promise {
    return fetch(this.backendUrl +
      '/domains/' + domain +
      '/collections/'+collectionKey +
      '/threads/' + threadId +
      '/comments/' + commentTimestamp, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.authToken,
      },
      body: JSON.stringify(commentUpdate)
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else {
        throw Error(response.statusText)
      }
    });
  }

  deleteComment(domain, collectionKey, threadId, commentTimestamp) : Promise {
    return fetch(this.backendUrl +
      '/domains/' + domain +
      '/collections/'+collectionKey +
      '/threads/' + threadId +
      '/comments/' + commentTimestamp, {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.authToken,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return ''
      } else {
        throw Error(response.statusText)
      }
    });
  }

  deleteThread(domain, collectionKey, threadId) : Promise {
    return fetch(this.backendUrl +
      '/domains/' + domain +
      '/collections/'+collectionKey +
      '/threads/' + threadId, {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': this.authToken,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return ''
      } else {
        throw Error(response.statusText)
      }
    });
  }
}

class DummyStorage {
  getUsagesForDomain(domainName) : Promise {
    return new Promise((resolve) => {
      resolve([
        {
          year: 2022,
          month: 3,
          plan: 'Basic',
          requestsQuota: 5000,
          requestsUsed: 1322,
        },
        {
          year: 2022,
          month: 4,
          plan: 'Basic',
          requestsQuota: 5000,
          requestsUsed: 2520,
        },
        {
          year: 2022,
          month: 5,
          plan: 'Basic',
          requestsQuota: 5000,
          requestsUsed: 4752,
        },
      ])
    })
  }

  listDomains() : Promise {
    return new Promise((resolve) => {
      resolve([
        {
          name: 'docs.kant.ai',
          aliases: [],
          apiKey: 'asfasdfnl234435681418764211',
        },
        {
          name: 'localhost',
          aliases: ['127.0.0.1'],
          apiKey: 'asfasdfnl234435681418764211',
        },
      ])
    })
  }

  listCollections(domain) : Promise {
    return new Promise((resolve) => {
      resolve([
        {
          name: '/my/first/collection',
          key: 'abcd4145256234134',
        },
        {
          name: '/my/seconds/collection',
          key: '25435bcda63568734',
        },
      ])
    })
  }

  getCollection(domain, collectionKey) : Promise {
    return new Promise((resolve) => {
      resolve({
        name: "/my/collection/path",
        key: collectionKey,
        threads: [
          {
            subject: { text: " My thread subject text and more and more and more and more and more consetetur sadipscing elitr, sed diam nonumy eirmod"},
            timestamp: 12345689934,
            comments: [{
              text: "Here my comment text",
              author: "ce",
              timestamp: 12345689934,
              needsApproval: false,
              isApproved: false,
              isSpam: false,
            }, {
              text: "And some other longer comment, that takes more space.",
              author: "ce",
              timestamp: 12345689936,
              needsApproval: true,
              isApproved: false,
              isSpam: true,
            }, {
              text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
              author: "ce",
              timestamp: 12345689938,
              needsApproval: false,
              isApproved: false,
              isSpam: false,
            }],
          },
          {
            subject: { text: " Another and more and more consetetur sadipscing elitr, sed diam nonumy eirmod"},
            timestamp: 12345689944,
            comments: [{
              text: "Here my comment text",
              author: "ce",
              timestamp: 12345689934,
              needsApproval: false,
              isApproved: false,
              isSpam: false,
            }, {
              text: "And some other longer comment, that takes more space.",
              author: "ce",
              timestamp: 12345689936,
              needsApproval: true,
              isApproved: true,
              isSpam: true,
            }, {
              text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
              author: "ce",
              timestamp: 12345689938,
              needsApproval: false,
              isApproved: false,
              isSpam: false,
            }],
          },
          {
            subject: { text: " more and more and more consetetur sadipscing elitr, sed diam nonumy eirmod"},
            timestamp: 12345689954,
            comments: [{
              text: "Here my comment text",
              author: "ce",
              timestamp: 12345689934,
              needsApproval: false,
              isApproved: false,
              isSpam: false,
            }, {
              text: "And some other longer comment, that takes more space.",
              author: "ce",
              timestamp: 12345689936,
              needsApproval: true,
              isApproved: false,
              isSpam: true,
            }, {
              text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
              author: "ce",
              timestamp: 12345689938,
              needsApproval: false,
              isApproved: false,
              isSpam: false,
            }],
          }
        ]
      })
    })
  }

  approveComment(domain, collectionKey, threadId, commentTimestamp) : Promise {
    return new Promise((resolve) => {
      resolve({})
    })
  }

  deleteComment(domain, collectionKey, threadId, commentTimestamp) : Promise {
    return new Promise((resolve) => {
      resolve({})
    })
  }

  createTrial(domain) : Promise {
    return new Promise((resolve) => {
      resolve({
        domain: domain,
        apiKey: 'my-api-key',
        accountId: 'my-account-id',
      })
    })
  }

  loginWithTrialAccountId(accountId) : Promise {
    return new Promise((resolve) => {
      resolve({
        authToken: 'my-auth-token',
      })
    })
  }
}


class BackendStorage {

  apiKey: string;
  backendUrl: string;

  constructor(backendUrl: string, apiKey: string) {
    this.apiKey = apiKey
    this.backendUrl = backendUrl
  }

  createTrial(domain) : Promise {
    return fetch(this.backendUrl+'/trial', {
      method : "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body : JSON.stringify({ domain: domain })
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json()
      } else {
        throw Error(response.statusText)
      }
    });
  }

  getUsagesForDomain(domainName) : Promise {
    return new Promise((resolve) => {
      resolve([
        {
          year: 2022,
          month: 3,
          plan: 'Basic',
          requestsQuota: 5000,
          requestsUsed: 1322,
        },
        {
          year: 2022,
          month: 4,
          plan: 'Basic',
          requestsQuota: 5000,
          requestsUsed: 2520,
        },
        {
          year: 2022,
          month: 5,
          plan: 'Basic',
          requestsQuota: 5000,
          requestsUsed: 4752,
        },
      ])
    })
  }

  listDomains() : Promise {
    return new Promise((resolve) => {
      resolve([
        {
          name: 'docs.kant.ai',
          aliases: [],
          apiKey: 'asfasdfnl234435681418764211',
        },
        {
          name: 'localhost',
          aliases: ['127.0.0.1'],
          apiKey: 'asfasdfnl234435681418764211',
        },
      ])
    })
  }

  listCollections(domain) : Promise {
    return new Promise((resolve) => {
      resolve([
        {
          name: '/my/first/collection',
          key: 'abcd4145256234134',
        },
        {
          name: '/my/seconds/collection',
          key: '25435bcda63568734',
        },
      ])
    })
  }

  getCollection(domain, collectionKey) : Promise {
    return new Promise((resolve) => {
      resolve({
        name: "/my/collection/path",
        key: collectionKey,
        threads: [
          {
            subject: { text: " My thread subject text and more and more and more and more and more consetetur sadipscing elitr, sed diam nonumy eirmod"},
            timestamp: 12345689934,
            comments: [{
              text: "Here my comment text",
              author: "ce",
              timestamp: 12345689934,
              needsApproval: false,
              isApproved: false,
              isSpam: false,
            }, {
              text: "And some other longer comment, that takes more space.",
              author: "ce",
              timestamp: 12345689936,
              needsApproval: true,
              isApproved: false,
              isSpam: true,
            }, {
              text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
              author: "ce",
              timestamp: 12345689938,
              needsApproval: false,
              isApproved: false,
              isSpam: false,
            }],
          },
          {
            subject: { text: " Another and more and more consetetur sadipscing elitr, sed diam nonumy eirmod"},
            timestamp: 12345689944,
            comments: [{
              text: "Here my comment text",
              author: "ce",
              timestamp: 12345689934,
              needsApproval: false,
              isApproved: false,
              isSpam: false,
            }, {
              text: "And some other longer comment, that takes more space.",
              author: "ce",
              timestamp: 12345689936,
              needsApproval: true,
              isApproved: true,
              isSpam: true,
            }, {
              text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
              author: "ce",
              timestamp: 12345689938,
              needsApproval: false,
              isApproved: false,
              isSpam: false,
            }],
          },
          {
            subject: { text: " more and more and more consetetur sadipscing elitr, sed diam nonumy eirmod"},
            timestamp: 12345689954,
            comments: [{
              text: "Here my comment text",
              author: "ce",
              timestamp: 12345689934,
              needsApproval: false,
              isApproved: false,
              isSpam: false,
            }, {
              text: "And some other longer comment, that takes more space.",
              author: "ce",
              timestamp: 12345689936,
              needsApproval: true,
              isApproved: false,
              isSpam: true,
            }, {
              text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
              author: "ce",
              timestamp: 12345689938,
              needsApproval: false,
              isApproved: false,
              isSpam: false,
            }],
          }
        ]
      })
    })
  }

  approveComment(domain, collectionKey, threadId, commentTimestamp) : Promise {
    return new Promise((resolve) => {
      resolve({})
    })
  }

  deleteComment(domain, collectionKey, threadId, commentTimestamp) : Promise {
    return new Promise((resolve) => {
      resolve({})
    })
  }

  loginWithTrialAccountId(accountId) : Promise {
    return new Promise((resolve) => {
      resolve({
        authToken: 'my-auth-token',
      })
    })
  }
}


class LocalStorage implements Storage {
  collectionKey: string;
  threads: Array<any>;

  constructor(collectionKey: string) {
    this.collectionKey = collectionKey
    this.threads = []
  }

  saveCommentToThread(thread: ThreadData, comment: CommentData) : Promise<[ThreadData, CommentData]> {
    return new Promise((resolve) => {
      const newThread = Object.assign({}, thread)
      if(! newThread.id) {
        newThread.timestamp = Date.now() / 1000
        newThread.id = String(newThread.timestamp)
      }
      const oldIndex = this.threads.findIndex((t) => t.id == newThread.id)
      if(oldIndex > -1) {
        this.threads.splice(oldIndex, 1)
      }
      const newComment = Object.assign({}, comment)
      newComment.timestamp = Date.now() / 1000
      newThread.comments = Object.assign([], newThread.comments);

      this.threads.push(newThread)
      newThread.comments.push(newComment)

      const threadsJson = JSON.stringify(this.threads)
      window.localStorage.setItem(this.collectionKey, threadsJson)
      resolve([newThread, newComment])
    })
  }

  loadThreads() : Promise<Array<ThreadData>> {
    return new Promise((resolve, reject) => {
      nextTick(() => {
        const threadsJson = window.localStorage.getItem(this.collectionKey)
        if (threadsJson) {
          const allThreads = JSON.parse(threadsJson)
          resolve(allThreads)
          this.threads = allThreads
        } else {
          reject("No threads found.")
        }
      })
    })
  }
}

export { createBackend }
